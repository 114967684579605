import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Accent } from '../Elements'
import { FormInput } from './Inputs'
import UseCreateInput from '../../hooks/UseCreateInput';



const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
        if (item.name === "Submit") return ""
        return {
            ...obj,
            [item[key]]: "",
        };
    }, initialValue);
};



const Form = ({ className, inputs, formName, submitText }) => {

    const initialInputs = convertArrayToObject([...inputs, {
        "name": "honeyPot"
    }], "name")

    const [formData, setFormData] = useState(initialInputs)


    const [formSent, setFormSent] = useState(false)


    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (formData.honeyPot) {
            return setFormData(initialInputs)
        }
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": formName, ...formData })
        })
            .then(() => setFormSent(true))
            .catch(error => console.log(error));


    };


    const handleChange = e => {
        return setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    if (formSent) return (
        <div className="relative w-full">
            <div className="text-primary relative z-10 w-full h-64 bg-secondary p-4 text-center flex flex-col justify-center">
                <h4>Thank you for submitting the form, we will be in contact with you as soon as we can.</h4>
                <Accent />
            </div>
            <Box top className="bg-accent z-0" />
        </div>
    )

    return (
        <form
            data-netlify="true"
            name={formName}
            className={`text-primary relative z-10 w-full ${className}`} onSubmit={(e) => handleSubmit(e)}>
            <div className="p-2 md:p-4 px-5 md:px-8 bg-black z-10 relative">
                <p className="hidden">
                    <label>Don’t fill this out if you're human: <input onChange={(e) => handleChange(e)} name="honeyPot" /></label>
                </p>
                {/* Creates the form inputs based of the Contentful data - or default props */}
                {
                    Array.isArray(inputs) && inputs.map(input =>
                        UseCreateInput({
                            ...input,
                            inputProps: {
                                onChange: (e) => handleChange(e),
                                value: formData[`${input.name}`]
                            }
                        }
                        ))

                }

                <FormInput
                    type="submit"
                    name="submit"
                    button
                    inputProps={{ children: submitText, className: "bg-accent-dark text-primary" }}
                />
            </div>
            <Box className="bg-accent z-0" top />
        </form>
    )
}




Form.propTypes = {
    formName: PropTypes.string.isRequired,
    submitText: PropTypes.string,
    inputs: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        placeholder: PropTypes.string.isRequired,
    }))

}
Form.defaultProps = {
    formName: 'Contact me',
    submitText: "Send Enquiry",
    inputs: [
        {
            type: "text",
            name: "Name",
            label: "Your Name",
            placeholder: "First Last"
        },
        {
            type: "email",
            name: "Email",
            label: "Your Email",
            placeholder: "email@example.com"

        },
        {
            type: "select",
            name: "Service",
            label: "Enquiry Type",
            options: [
                "General",
                "Option 2",
                "Option 3"
            ]
        },
        {
            type: "textArea",
            name: "Message",
            label: "Your Message",
            placeholder: `Hi there, \nI would love to work together on a project. Could we jump on a call to discuss it?`
        }
    ]
}

export default Form
