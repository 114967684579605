import React, { useState } from "react"
import PropTypes from "prop-types"

// Components
import { AnchorLink } from "gatsby-plugin-anchor-links";
import UseBodyLock from "../hooks/UseBodyLock";
import Linked from "./Linked";
import SocialLinks from "./SocialLinks";
import Headroom from 'react-headroom';

// Media
import AntlerDigitalLogo from '../images/antler-digital-mini.svg'

const Nav = ({ links, socialLinks, homeLink, homeImage }) => {

  const [menuOpen, setMenuOpen] = useState(false)
  const [fixed, setFixed] = useState(false)

  // Manages the scroll locking when menu is open
  UseBodyLock(menuOpen);

  const image = homeImage ? homeImage.sizes.src : null


  return (
    <header className="font-light overflow-hidden">

      {/* Desktop Menu */}
      < Headroom
        onPin={() => setFixed(true)}
        onUnfix={() => setFixed(false)}
        className="hidden z-40 lg:block absolute w-full">
        <div>
          <nav className={`w-full p-4 h-24  z-40 ${fixed ? "bg-primary shadow-2xl" : "bg-transparent"} `}>
            <ul className="flex justify-end max-w-5xl mx-auto ">

              <li className="self-start hover:text-accent mx-4 mr-auto" >
                <AnchorLink to={`/${homeLink.linkTo}`}>
                  {image ?
                    <img className="w-16 h-16" src={image} alt="Logo Home" /> :
                    "Home"}
                </AnchorLink></li>

              {links.map((link, index) => <li key={`${index}-${link.name}`} className="inline-block mx-4 hover:text-accent self-center">
                <AnchorLink key={`${link.name}-${index}`} to={`/${link.linkTo}`}>{link.name}
                </AnchorLink>
              </li>)}
            </ul>
          </nav>
        </div>

      </Headroom>



      {/* Mobile Menu */}
      <div className="lg:hidden">

        <i 
          role="button"
          tabIndex={0}
          onKeyDown={ (e) => e.keyCode === 13 || e.keyCode === 32  ? setMenuOpen(!menuOpen) : "" }
          onClick={() => { setMenuOpen(!menuOpen) }} className="fa fa-bars text-4xl p-8 fixed z-40 cursor-pointer" />
        <nav 
          role="navigation"
          className="w-4/5 p-4 flex flex-col fixed z-40 lg:hidden bg-secondary h-screen text-primary-dark transition-all duration-500"
          style={{
            left: menuOpen ? '0vw' : '-100vw'
          }}
        >
          <i 
            role="button"
            tabIndex={0}
            onKeyDown={ (e) => e.keyCode === 13 || e.keyCode === 32  ? setMenuOpen(!menuOpen) : "" }
            onClick={() => { setMenuOpen(!menuOpen) }} className="fa fa-times text-5xl p-4 text-primary-dark z-40 cursor-pointer" />

          {/* Menu Items */}
          <ul className="flex flex-col justify-end max-w-5xl mx-auto" >
            <li
            onKeyDown={ (e) => e.keyCode === 13 || e.keyCode === 32  ? setMenuOpen(false) : "" }
            onClick={() => setMenuOpen(false)} 
            className="inline-block mx-4 text-3xl my-2" ><AnchorLink to={`/${homeLink.linkTo}`}>Home</AnchorLink></li>
            {
              links.map((link, index) => (
                <li 
                  key={`${index}-${link.name}`} 
                  onKeyDown={ (e) => e.keyCode === 13 || e.keyCode === 32  ? setMenuOpen(false) : "" }
                  onClick={() => setMenuOpen(false)}
                  className="inline-block mx-4 text-3xl my-2">
                  <AnchorLink key={`${link.name}-${index}`}  to={`/${link.linkTo}`}>{link.name}</AnchorLink>
                </li>
              ))
            }
          </ul>

          <aside className="flex flex-1 flex-col text-center justify-between">
            <SocialLinks className="text-primary-dark" links={socialLinks} />
            <Linked linkTo="https://antler.digital">
              <img className="w-12 h-12 self-end mx-auto block" src={AntlerDigitalLogo} alt="Antler Digital Logo" />
              <small className="self-end mx-auto block w-full">Designed and built by Antler Digital</small>
            </Linked>
          </aside>

        </nav>
      </div>


    </header>
  )
}

Nav.propTypes = {
  links: PropTypes.arrayOf(PropTypes.exact({
    name: PropTypes.string,
    linkTo: PropTypes.string
  }))
}


Nav.defaultProps = {
  links: [
    {
      name: "Home",
      linkTo: "#home",
    },
    {
      name: "Services",
      linkTo: "#services",
    },
    {
      name: "Testimonials",
      linkTo: "#testimonials",
    },
    {
      name: "About",
      linkTo: "#about",
    },
    {
      name: "Work with me",
      linkTo: "#contact-me",
    }
  ],
}

export default Nav
