import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import Paint from '../../images/js/Paint'


const Section = ({ children, title, className, img, id, ...restProps }) => (
    <div className="w-full overflow-hidden">
      <section 
        id={id.trim().replace(/ /, "-")}
        {...restProps} 
        className={`${img ? "" : "max-w-5xl px-5"} bg-primary min-h-screen mx-auto font-thin relative mb-24 lg:mb-32 ${className}`}>
        {title ? <div className="flex justify-center">
            <header className="relative inline-block mx-auto">
              <Paint className="text-black transform rotate-180" />
              <h2 className="px-24 text-center my-24 relative z-10 text-primary text-2xl md:text-4xl">{title}</h2>
              </header>
              </div>
          : ""}
        {img ? <Img className="fixed w-full h-screen" fluid={img} /> : ""}
        {img ?
          <div className="absolute z-10 top-0 w-full h-full flex ">
            {children}
          </div> : children
        }
      </section>
    </div>
)

Section.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
      ]),
    title: PropTypes.string,
    className: PropTypes.string,
    img: PropTypes.object
}

export default Section
