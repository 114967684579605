import React from 'react'

export const FormInput = ({ type = "text", placeholder = "", label, name = "", textArea, button, labelProps = {}, inputProps = {}, rows = 4, }) => {
  return (<div className="my-4">
    <label
      {...labelProps}
      htmlFor={name}
      className={`block w-full my-2 ${!label ? "hidden" : ""} ${labelProps.className || ""}`}>
      {label}
    </label>
    {textArea ?
      <textarea 
          name={name} 
          {...inputProps} 
          className={`p-2 w-full bg-dark-accent text-primary resize-y ${inputProps.className || ""}`} 
          placeholder={placeholder} 
          rows={rows}
        >
      </textarea>
      : button ? <button className={`p-2 mt-2 w-full bg-dark-accent hover:bg-accent ${inputProps.className || ""}`} type={type} >{inputProps.children} </button> :
        <input
          {...inputProps}
          name={name}
          type={type}
          placeholder={placeholder}
          className={`p-2 w-full bg-dark-accent ${inputProps.className || ""}`} ></input>
    }
  </div>)
}

export const FormSelect = ({ type = "text", options = [], label, name = "", labelProps = {}, inputProps = {} }) => {
  return (
  <div className="my-2">
    <label
      {...labelProps}
      htmlFor={name}
      className={`block w-full my-2 ${!label ? "hidden" : ""} ${labelProps.className || ""}`}>
      {label}
    </label>
    <div className="relative">
      <select
        name={name}
        {...inputProps}
        className={`p-2 w-full bg-gray-800 appearance-none   px-4 pr-8 ${inputProps.className || ""}`}
        
      >
        <option className="p-4" value={""} disabled>Please select one</option>
        {options.map((option, index) => (
          <option key={`${option}-${index}`} className="p-4" value={option}>{option}</option>
        ))}

      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-primary">
        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
      </div>
    </div>
  </div>)
}